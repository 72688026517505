import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TheNavigation from "@/components/TheNavigation.vue";
import TheHeader from "@/components/TheHeader.vue";

const app = createApp(App);

app.component("the-navigation", TheNavigation);
app.component("the-header", TheHeader);

app.use(store).use(router).mount("#app");
