<template>
  <nav>
    <ul>
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/neonconfig">Neon Configurator</router-link>
      </li>
      <li>
        <router-link to="/refs">References</router-link>
      </li>
      <li>
        <router-link to="/inqury">Inqury</router-link>
      </li>
      <li>
        <router-link to="/blog">Blog</router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
}
li {
  margin: 10px;
}
</style>
